export default {
  BUTTON_PRIMARY_CLASS: 'button button--regular button--solid',
  BUTTON_SECONDARY_CLASS: 'button button--regular',
  FACET_BUTTON_CLASS: '',

  CLEAR_TEXT: 'Clear',

  SELECT_CLASS: '',

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  SEARCH_BOX_FORM_CLASS:
    'button button--outline button--icon button--outline-hover button--no-padding button--no-hover site-search-handle',
  SEARCH_BOX_BUTTON_CLASS: 'button__icon',
  SEARCH_BOX_PLACEHOLDER: 'Search for...',
};
