import shopifyGenericDefaults from '../shopify-generic/config.js';

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['SearchBox'].includes(w.name)),
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { replace: '.header-actions search-form' },
      onDropdownItemsReceived: shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBox')
        .onDropdownItemsReceived,
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxMobile',
      type: 'SearchBoxDialogButton',
      location: { replace: '.header-container.mobile-search search-form' },
      onDropdownItemsReceived: shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBox')
        .onDropdownItemsReceived,
      template: 'searchBox/dialogButtonAdaptable',
    },
  ],
};
