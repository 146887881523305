
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'width': '22',
                'height': '22',
                'viewBox': '0 0 22 22',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg'
            }, _createElement('circle', {
                'cx': '10.5',
                'cy': '10.5',
                'r': '7.5',
                'stroke': 'black',
                'strokeWidth': '2'
            }), _createElement('path', {
                'd': 'M17.1213 15.2929L16.4142 14.5858L15 16L15.7071 16.7071L17.1213 15.2929ZM19.2426 20.2426C19.6331 20.6332 20.2663 20.6332 20.6568 20.2426C21.0473 19.8521 21.0473 19.219 20.6568 18.8284L19.2426 20.2426ZM15.7071 16.7071L19.2426 20.2426L20.6568 18.8284L17.1213 15.2929L15.7071 16.7071Z',
                'fill': 'black',
                'style': { stroke: 'none !important' }
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]