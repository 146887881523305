
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function repeatI3(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__empty'
                    });
                }
                return _createElement('div', {
                    'id': 'product-item-' + this.id,
                    'className': 'product-item card',
                    'data-js-product-item': true
                }, _createElement('a', {
                    'href': this.url,
                    'className': 'card__image product-item__image',
                    'style': { paddingTop: '100%' }
                }, _createElement('figure', {
                    'className': 'lazy-image product-item__image-figure product-item__image-figure--primary lazy-image--animation lazy-image--background lazy-image--fit lazyloaded',
                    'data-crop': 'true'
                }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'className': 'img',
                    'width': '1000',
                    'height': '1000',
                    'onError': this.onImageError
                }))), _createElement('div', { 'className': 'card__text product-item__text gutter--regular spacing--xlarge remove-empty-space text-align--left' }, _createElement('a', {
                    'className': 'product-item__title',
                    'href': this.url,
                    'title': this.removeHTML(this.title)
                }, _createElement('div', { 'className': 'remove-line-height-space--small' }, _createElement('span', mergeProps({ 'className': 'text-animation--underline text-size--large text-line-height--small text-weight--bold text-animation--underline' }, { dangerouslySetInnerHTML: { __html: this.title } })))), _createElement('div', { 'className': 'product-item__price text-size--large equalize-white-space' }, _createElement('div', { 'className': 'remove-line-height-space' }, _createElement('div', { 'className': 'product-price' }, _createElement('span', { 'className': 'product-price--original' }, this.formatPrice(this.price)), this.on_sale ? _createElement('del', {
                    'className': 'product-price--compare',
                    'key': '1284'
                }, '\n            ', this.formatPrice(this.compare_at_price), '\n          ') : null)))), _createElement('div', {
                    'className': 'product-item__text gutter--regular spacing--xlarge remove-empty-space text-align--left',
                    'style': { paddingTop: '0' }
                }, _createElement('div', { 'className': 'product-item__quick-buy' }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'button button--solid button--regular button--fullwidth button--loader',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '16351'
                    }, _createElement('span', { 'className': 'button__text' }, 'Choose options'))] : null, this.variant_ids.length === 1 ? [_createElement('quick-add-to-cart', {
                        'key': '19141',
                        'class': 'quick-add-to-cart'
                    }, _createElement('product-form', { 'data-ajax-cart': true }, _createElement('div', { 'className': 'quick-add-to-cart-button' }, _createElement('form', {
                        'method': 'post',
                        'action': '/cart/add',
                        'id': 'product_form_' + this.id,
                        'acceptCharset': 'UTF-8',
                        'className': 'shopify-product-form',
                        'encType': 'multipart/form-data'
                    }, _createElement('input', {
                        'type': 'hidden',
                        'name': 'form_type',
                        'value': 'product'
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'utf8',
                        'value': '\u2713'
                    }), _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids
                    }), _createElement('button', {
                        'className': 'button button--solid button--regular button--fullwidth button--loader',
                        'data-js-product-add-to-cart': true,
                        'type': 'submit',
                        'name': 'add'
                    }, _createElement('span', { 'className': 'button__text' }, 'Add to cart'), _createElement('span', { 'className': 'button__preloader' }, _createElement('svg', {
                        'className': 'button__preloader-element',
                        'viewBox': '25 25 50 50'
                    }, _createElement('circle', {
                        'cx': '50',
                        'cy': '50',
                        'r': '20',
                        'fill': 'none',
                        'strokeWidth': '4'
                    })))), _createElement('input', {
                        'type': 'hidden',
                        'name': 'product-id',
                        'value': this.id
                    })))))] : null), [
                    this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '32000'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null,
                    !this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '32002'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI3.bind(this)),
                        _createElement('span', {}, 'No reviews')
                    ]) : null
                ]));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []